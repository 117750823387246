.body {
    width: 210mm;
    height: 297mm;
    background-color: #e3f9b4;
    position: relative;
    padding: 10mm;
    box-sizing: border-box;
}

.side {
    width: 5%;
    float: left;
    height: 100%;
}

.icon {
    height: auto;
    width: 100%;
}

.header {
    position: relative;
    height: auto;
    width: 90%;
    text-align: center;
    font-size: 24px;
    font-family: fantasy;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 10mm;
}

.csvg {
    width: 100%;
    max-width: 98%;
    margin-top: -10%;
}

.cpath {
    fill: transparent;
}

.ctext {
    font-size: 26px;
}

.text2 {
    height: auto;
    width: 65%;
    text-align: center;
    font-size: 12px;
    line-height: 1.2;
    font-weight: 500;
    margin: -440px auto;
    margin-bottom: 5mm;
    margin-left: 20%;
}

.text3 {
    height: auto;
    width: 32%;
    text-align: center;
    font-size: 10px;
    line-height: 1.2;
    font-weight: 500;
    border: 1px solid;
    padding: 5px;
    box-shadow: -7px -5px 4px #000000;
    font-family: cursive;
    margin: 0px auto;
    margin-bottom: 5mm;
    margin-left: 36%;
}

.textDate {
    height: auto;
    width: 90%;
    text-align: center;
    font-size: 16px;
    margin: -62px auto;
    margin-bottom: 5mm;
}

.sl {
    position: relative;
    font-family: cursive;
}

.date {
    position: relative;
    margin-left: 67%;
    font-family: cursive;
}

.dateIn {
    margin-top: 14%;
    position: relative;
    height: 16px;
    margin-left: 14%;
    background: transparent;
    font-size: 14px;
    width: 78px;
    margin-left: 1%;
}

.allText {
    position: relative;
    height: auto;
    width: 34%;
    margin: 0 auto;
    font-family: cursive;
    font-size: 14px;
    margin-bottom: 5mm;
    margin-left: 9%;
}

.allTextTable {
    position: relative;
    width: 80%;
    margin: 22% auto;
    font-family: cursive;
    font-size: 14px;
    margin-bottom: 5mm;
}

.allTextFields {
    position: relative;
    width: 48%;
    margin: -48% 48% auto;
    font-family: cursive;
    font-size: 10px;
    background-color: transparent;
    height: auto;
    margin-bottom: 5mm;
}

.name,
.father,
.residence,
.admission,
.register,
.birth,
.institute,
.reading,
.higher{
    position: relative;
    height: 16px;
    background: transparent;
    font-size: 13px;
    width: 100%;
    margin-top: 5px;
    padding: 3px;
}

.current{
    position: absolute;
    height: 16px;
    background: transparent;
    font-size: 13px;
    width: 100%;
    margin-top: 38%;
    padding: 3px;
    margin-left: -100%;
}

.dofadd{
    position: absolute;
    height: 16px;
    background: transparent;
    font-size: 13px;
    width: 25%;
    margin-top: 62%;
    margin-left: -126%;
}
.dofleave{
    position: absolute;
    height: 16px;
    background: transparent;
    font-size: 13px;
    width: 25%;
    margin-top: 62%;
    margin-left: -60%;
}

.Character{
    position: absolute;
    height: 16px;
    background: transparent;
    font-size: 13px;
    width: 100%;
    margin-top: 73%;
    margin-left: -100%;
}
.reason{
    position: absolute;
    height: 16px;
    background: transparent;
    font-size: 13px;
    width: 100%;
    margin-top: 79%;
    margin-left: -100%;
}
.dissue{
    position: absolute;
    height: 16px;
    background: transparent;
    font-size: 13px;
    width: 100%;
    margin-top: 85%;
    margin-left: -100%;
}
.issue{
    position: absolute;
    height: 16px;
    background: transparent;
    font-size: 13px;
    width: 100%;
    margin-top: 91%;
    margin-left: -100%;
}

.sign {
    position: absolute;
    height: 16px;
    background: transparent;
    font-size: 13px;
    width: 19%;
    margin-top: 5px;
    text-align: center;
    margin-top: 111%;
    margin-left: -32%;
}
table,
td,
th {
    border: 1px solid;
    padding: 4px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

.allText2 {
    position: relative;
    height: auto;
    width: 34%;
    margin: 0 auto;
    font-family: cursive;
    font-size: 14px;
    margin-bottom: 5mm;
    margin-left: 9%;
}

.principal {
    position: relative;
    height: auto;
    width: 90%;
    margin: 74px auto;
    font-family: cursive;
    font-size: 18px;
    text-align: end;
    margin-bottom: 5mm;
}

@media print {
    @page {
        size: A4;
        margin: 10mm;
    }

    .body {
        width: 210mm;
        height: 297mm;
        background-color: #e3f9b4;
        position: relative;
        padding: 10mm;
        box-sizing: border-box;
    }
    
    .side {
        width: 5%;
        float: left;
        height: 100%;
    }
    
    .icon {
        height: auto;
        width: 100%;
    }
    
    .header {
        position: relative;
        height: auto;
        width: 90%;
        text-align: center;
        font-size: 24px;
        font-family: fantasy;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 10mm;
    }
    
    .csvg {
        width: 100%;
        max-width: 98%;
        margin-top: -10%;
    }
    
    .cpath {
        fill: transparent;
    }
    
    .ctext {
        font-size: 26px;
    }
    
    .text2 {
        height: auto;
        width: 65%;
        text-align: center;
        font-size: 12px;
        line-height: 1.2;
        font-weight: 500;
        margin: -440px auto;
        margin-bottom: 5mm;
        margin-left: 20%;
    }
    
    .text3 {
        height: auto;
        width: 32%;
        text-align: center;
        font-size: 10px;
        line-height: 1.2;
        font-weight: 500;
        border: 1px solid;
        padding: 5px;
        box-shadow: -7px -5px 4px #000000;
        font-family: cursive;
        margin: 0px auto;
        margin-bottom: 5mm;
        margin-left: 36%;
    }
    
    .textDate {
        height: auto;
        width: 90%;
        text-align: center;
        font-size: 16px;
        margin: -62px auto;
        margin-bottom: 5mm;
    }
    
    .sl {
        position: relative;
        font-family: cursive;
    }
    
    .date {
        position: relative;
        margin-left: 67%;
        font-family: cursive;
    }
    
    .dateIn {
        margin-top: 14%;
        position: relative;
        height: 16px;
        margin-left: 14%;
        background: transparent;
        font-size: 14px;
        width: 78px;
        margin-left: 1%;
    }
    
    .allText {
        position: relative;
        height: auto;
        width: 34%;
        margin: 0 auto;
        font-family: cursive;
        font-size: 14px;
        margin-bottom: 5mm;
        margin-left: 9%;
    }
    
    .allTextTable {
        position: relative;
        width: 80%;
        margin: 22% auto;
        font-family: cursive;
        font-size: 14px;
        margin-bottom: 5mm;
    }
    
    .allTextFields {
        position: relative;
        width: 48%;
        margin: -48% 48% auto;
        font-family: cursive;
        font-size: 10px;
        background-color: transparent;
        height: auto;
        margin-bottom: 5mm;
    }
    
    .name,
    .father,
    .residence,
    .admission,
    .register,
    .birth,
    .institute,
    .reading,
    .higher{
        position: relative;
        height: 16px;
        background: transparent;
        font-size: 13px;
        width: 100%;
        margin-top: 5px;
        padding: 3px;
    }
    
    .current{
        position: absolute;
        height: 16px;
        background: transparent;
        font-size: 13px;
        width: 100%;
        margin-top: 38%;
        padding: 3px;
        margin-left: -100%;
    }
    
    .dofadd{
        position: absolute;
        height: 16px;
        background: transparent;
        font-size: 13px;
        width: 25%;
        margin-top: 62%;
        margin-left: -126%;
    }
    .dofleave{
        position: absolute;
        height: 16px;
        background: transparent;
        font-size: 13px;
        width: 25%;
        margin-top: 62%;
        margin-left: -60%;
    }
    
    .Character{
        position: absolute;
        height: 16px;
        background: transparent;
        font-size: 13px;
        width: 100%;
        margin-top: 73%;
        margin-left: -100%;
    }
    .reason{
        position: absolute;
        height: 16px;
        background: transparent;
        font-size: 13px;
        width: 100%;
        margin-top: 79%;
        margin-left: -100%;
    }
    .dissue{
        position: absolute;
        height: 16px;
        background: transparent;
        font-size: 13px;
        width: 100%;
        margin-top: 85%;
        margin-left: -100%;
    }
    .issue{
        position: absolute;
        height: 16px;
        background: transparent;
        font-size: 13px;
        width: 100%;
        margin-top: 91%;
        margin-left: -100%;
    }
    
    .sign {
        position: absolute;
        height: 16px;
        background: transparent;
        font-size: 13px;
        width: 19%;
        margin-top: 5px;
        text-align: center;
        margin-top: 111%;
        margin-left: -32%;
    }
    table,
    td,
    th {
        border: 1px solid;
        padding: 4px;
    }
    
    table {
        width: 100%;
        border-collapse: collapse;
    }
    
    .allText2 {
        position: relative;
        height: auto;
        width: 34%;
        margin: 0 auto;
        font-family: cursive;
        font-size: 14px;
        margin-bottom: 5mm;
        margin-left: 9%;
    }
    
    .principal {
        position: relative;
        height: auto;
        width: 90%;
        margin: 74px auto;
        font-family: cursive;
        font-size: 18px;
        text-align: end;
        margin-bottom: 5mm;
    }
}
