/* NavbarNew.css */
.navbar {
  background-color: #C4DFDF;
  overflow: hidden;
  position: fixed;
  width: 100%;
  
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;
}

.navbar-logo {
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  margin-left: -80%;
  cursor: pointer;
  font-family: Arial;
}

.menu-icon {
  color: #000000;
  font-size: 24px;
  cursor: pointer;
  position: relative; /* Ensure dropdown is positioned relative to this */
}

.profile {
  margin-right: 5px;
  color: #000000;
  cursor: pointer;
  text-decoration: underline;
  font-family: Arial;
}

.profile-text {
  margin-right: 5px;
  color: #000000;
  cursor: pointer;
}

.dropdown-content {
  font-family: Arial;
  position: fixed;
  background-color: #C4DFDF;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  text-align: center;
  cursor: pointer;
  top: 50px; 
}

.dropdown-content a, .dropdown-content div {
  color: #000000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  position: relative;
}

/* .dropdown-content a:hover, .dropdown-content div:hover {
  background-color: #E3F4F4;
} */
.nested{
  background-color: transparent;
  font-size: small;
}
.hover:hover{
  background-color: #D2E9E9;
}
.second:hover{
  background-color: #E3F4F4;
}

@media only screen and(main-width: 300px) {
  .navbar-logo {
    margin-left: -11%;
    font-size: small;
  }
}
@media only screen and (min-width: 320px){ 
  .navbar-logo{
    margin-left: -9%;
    font-size: medium;
  }
}
@media only screen and (min-width: 375px){
  .navbar-logo {
    margin-left: -20%;
    font-size: large;
  }
}
@media only screen and (min-width: 425px){
  .navbar-logo {
    margin-left: -28%;
  }
}
@media only screen and (min-width: 768px){
  .navbar-logo {
    margin-left: -60%;
  }
}
@media only screen and (min-width: 1024px){
  .navbar-logo {
    margin-left: -69%;
    font-size: x-large;
  }
}
@media only screen and (min-width: 1440px){
  .navbar-logo {
    margin-left: -73%;
        font-size: xx-large;
  }
}
@media only screen and (min-width: 2560px){
  .navbar-logo {
    margin-left: -80%;
    font-size: x-large;
  }
}