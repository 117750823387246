.logout-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
   
    
  }
  
  .logout-box {
    position: relative;
    z-index: 10;
    
    background-color: whitesmoke;
    padding: 30px;
    border-radius: 10px;
    height: auto;
    max-width: 400px;
  }
  
  @keyframes wander {
    0% {
      transform: translate(0, 0);
    }
    25% {
      transform: translate(100px, -50px);
    }
    50% {
      transform: translate(-100px, 100px);
    }
    75% {
      transform: translate(50px, -100px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  
  .circle {
    position: absolute;
    border-radius: 50%;
    opacity: 0.5;
    animation: wander 15s infinite;
  }

  .rectangle {
    position: absolute;
    border-radius: 10%;
    opacity: 0.4;
    animation: wander 15s infinite;
  }
  
  .circle1 {
    width: 100px;
    height: 100px;
    background: rgba(107, 169, 169, 0.4);
    top: 10%;
    left: 10%;
    animation-delay: 0s;
  }
  .circle6 {
    width: 100px;
    height: 100px;
    background: rgba(107, 169, 169, 0.4);
    top: 40%;
    left: 10%;
    animation-delay: 0s;
  }
  
  .circle2 {
    width: 80px;
    height: 80px;
    background: rgba(107, 169, 169, 0.6);
    top: 30%;
    left: 70%;
    animation-delay: 3s;
  }
  
  .circle3 {
    width: 120px;
    height: 120px;
    background: rgba(107, 169, 169, 0.5);
    top: 50%;
    left: 50%;
    animation-delay: 6s;
  }

  .circle4 {
    width: 120px;
    height: 120px;
    background: rgba(107, 169, 169, 0.5);
    top: 10%;
    left: 80%;
    animation-delay: 8s;
  }
  .circle5 {
    width: 120px;
    height: 120px;
    background: rgba(107, 169, 169, 0.5);
    top: 70%;
    left: 50%;
    animation-delay: 6s;
  }
  